import * as CONST from '../ActionTypes';

const initialState = {
  questionnairesList: [],
};

function questionnaires(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_QUESTION_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          questionnairesList: action.payload,
        },
      );
    case CONST.GET_QUESTION_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          questionnairesList: [],
        },
      );
    default:
      return state;
  }
}

export default questionnaires;
