import * as CONST from '../ActionTypes';

const initialState = {
  suggestionList: [],
};

function suggestion(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_SUGGESTIONS_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          suggestionList: action.payload,
        },
      );
    case CONST.GET_SUGGESTIONS_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          suggestionList: [],
        },
      );
    default:
      return state;
  }
}

export default suggestion;
