import * as CONST from '../ActionTypes';

const initialState = {
  contentList: [],
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
  errorMessage: '',
  isSuccessSupport: false,
};

function contents(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        contentList: action.payload,
      });
    case CONST.GET_LIST_CONTENT_FAILURE:
      return Object.assign({}, state, {
        contentList: [],
      });
      // -----------------
      // queryパラメータの設定
      // -----------------
    case CONST.SET_CONTENT_QUERY:
      return Object.assign({}, state, {
        query: action.payload,
      });
    case CONST.CREATE_SUPPORTING_SUCCESS:
      return {
        ...state,
        isSuccessSupport: true,
      };
    case CONST.CREATE_SUPPORTING_FAILURE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
      });
    case CONST.CLEAR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: '',
        isSuccessSupport: false,
      });
    default:
      return state;
  }
}


export default contents;
