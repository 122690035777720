import * as CONST from '../ActionTypes';

const initialState = {
  loading: false,
};

function loading(state = initialState, action) {
  switch (action.type) {
    case CONST.LOADING:
      return Object.assign(
        {},
        state,
        { loading: true },
      );
    case CONST.UNLOADING:
      return Object.assign(
        {},
        state,
        { loading: false },
      );
    default:
      return state;
  }
}

export default loading;
