import * as CONST from '../ActionTypes';

const initialState = {
  accessDenied: false,
};

function error(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // ACCESS_DENIED
    // -----------------
    case CONST.ACCESS_DENIED:
      return Object.assign(
        {},
        state,
        {
          accessDenied: true,
        },
      );
    // -----------------
    // クリアエラーフラグ
    // -----------------
    case CONST.CLEAR_ERROR_FLG:
      return Object.assign(
        {},
        state,
        {
          accessDenied: false,
        },
      );
    default:
      return state;
  }
}

export default error;
