import * as CONST from '../ActionTypes';

const initialState = {
  notificationList: [],
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function questionnaires(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_NOTIFICATION_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          notificationList: action.payload,
        },
      );
    case CONST.GET_LIST_NOTIFICATION_FAILURE:
      return Object.assign(
        {},
        state,
        {
          notificationList: [],
        },
      );
      // -----------------
    // queryパラメータの設定
    // -----------------
    case CONST.SET_NOTIFICATION_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: action.payload,
        },
      );
    default:
      return state;
  }
}

export default questionnaires;
