import * as CONST from '../ActionTypes';

const initialState = {
  deliveryReqList: [],
  allDeliveryReqList: [],
  csvDeliveryReqList: [],
  deliveryReq: {},
  searchParams: {
    deliveryReqName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    createdAtFrom: '',
    createdAtTo: '',
    prefectureId: '',
    gender: '',
    birthDateFrom: '',
    birthDateTo: '',
    birthMonth: '',
  },
  sortParams: {
    createdAtAsc: true,
    idAsc: true,
    prefAsc: true,
    nameKanaAsc: true,
    birthdayAsc: true,
  },
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
  isSuccessMail: false,
  isFailureMail: false,
  errorMessageMail: '',
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function deliveryRequest(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 顧客一覧取得
    // -----------------
    case CONST.GET_DELIVERY_REQ_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          deliveryReqList: action.payload,
        },
      );
    case CONST.GET_DELIVERY_REQ_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          deliveryReqList: [],
        },
      );
    // -----------------
    // 顧客全件取得
    // -----------------
    case CONST.GET_ALL_DELIVERY_REQ_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          allDeliveryReqList: action.payload,
        },
      );
    case CONST.GET_ALL_DELIVERY_REQ_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          allDeliveryReqList: [],
        },
      );
    // -----------------
    // 顧客一覧取得(CSV用)
    // -----------------
    case CONST.GET_CSV_DELIVERY_REQ_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          csvDeliveryReqList: action.payload,
        },
      );
    case CONST.GET_CSV_DELIVERY_REQ_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          csvDeliveryReqList: [],
        },
      );
    // -----------------
    // 顧客詳細取得
    // -----------------
    case CONST.GET_DELIVERY_REQ_DETAIL_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          deliveryReq: action.payload,
        },
      );
    case CONST.GET_DELIVERY_REQ_DETAIL_FAILURE:
      return Object.assign(
        {},
        state,
        {
          deliveryReq: {},
        },
      );
    // -----------------
    // 検索パラメーターセット
    // -----------------
    case CONST.SET_DELIVERY_REQ_SEARCH_PARAMS:
      return Object.assign(
        {},
        state,
        action.payload,
      );
    // -----------------
    // 検索パラメーターリセット
    // -----------------
    case CONST.CLEAR_DELIVERY_REQ_SEARCH_PARAMS:
      return Object.assign(
        {},
        state,
        {
          searchParams: {
            deliveryReqListName: '',
            companyName: '',
            email: '',
            phoneNumber: '',
            createdAtFrom: '',
            createdAtTo: '',
            prefectureId: '',
            gender: '',
            birthDateFrom: '',
            birthDateTo: '',
            birthMonth: '',
          },
          sortParams: {
            createdAtAsc: true,
            idAsc: true,
            prefAsc: true,
            nameKanaAsc: true,
            birthdayAsc: true,
          },
        },
      );
    // -----------------
    // 顧客削除
    // -----------------
    case CONST.DELETE_DELIVERY_REQ_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isSuccess: true,
          isFailure: false,
          errorMessage: '',
        },
      );
    case CONST.DELETE_DELIVERY_REQ_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isSuccess: false,
          isFailure: true,
          errorMessage: action.payload,
        },
      );
    // -----------------
    // 結果フラグクリア
    // -----------------
    case CONST.CLEAR_DELETE_DELIVERY_REQ_RESULT_FLG:
      return Object.assign(
        {},
        state,
        {
          isSuccess: false,
          isFailure: false,
          errorMessage: '',
        },
      );
    // -----------------
    // 顧客にメール送信
    // -----------------
    case CONST.SEND_MAIL_DELIVERY_REQ_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: true,
          isFailureMail: false,
          errorMessageMail: '',
        },
      );
    case CONST.SEND_MAIL_DELIVERY_REQ_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: false,
          isFailureMail: true,
          errorMessageMail: action.payload,
        },
      );
    // -----------------
    // メール送信結果フラグクリア
    // -----------------
    case CONST.CLEAR_SEND_MAIL_DELIVERY_REQ_RESULT_FLG:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: false,
          isFailureMail: false,
          errorMessageMail: '',
        },
      );
    // -----------------
    // queryパラメータの設定
    // -----------------
    case CONST.SET_DELIVERY_REQ_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: Object.assign(
            {},
            state,
            action.payload,
          ),
        },
      );
    // -----------------
    // queryパラメータのクリア
    // -----------------
    case CONST.CLEAR_DELIVERY_REQ_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: {
            deliveryReqListName: '',
            companyName: '',
            email: '',
            phoneNumber: '',
            createdAtFrom: '',
            createdAtTo: '',
            prefectureId: '',
            gender: '',
            birthDateFrom: '',
            birthDateTo: '',
            birthMonth: '',
            sort: 'created_at',
            isAsc: false,
            currentPage: 0,
            itemsPerPage: 10,
          },
        },
      );
    default:
      return state;
  }
}

export default deliveryRequest;
