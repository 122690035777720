import * as CONST from '../ActionTypes';

const initialState = {
  driverList: [],
  allDriverList: [],
  driver: {},
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
  isSuccessMail: false,
  isFailureMail: false,
  errorMessageMail: '',
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function driver(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 顧客一覧取得
    // -----------------
    case CONST.GET_DRIVER_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          driverList: action.payload,
        },
      );
    case CONST.GET_DRIVER_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          driverList: [],
        },
      );
    // -----------------
    // 顧客全件取得
    // -----------------
    case CONST.GET_ALL_DRIVER_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          allDriverList: action.payload,
        },
      );
    case CONST.GET_ALL_DRIVER_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          allDriverList: [],
        },
      );
    case CONST.GET_DRIVER_DETAIL_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          driver: action.payload,
        },
      );
    case CONST.GET_DRIVER_DETAIL_FAILURE:
      return Object.assign(
        {},
        state,
        {
          driver: {},
        },
      );
    // -----------------
    // 検索パラメーターセット
    // -----------------
    case CONST.SET_DRIVER_SEARCH_PARAMS:
      return Object.assign(
        {},
        state,
        action.payload,
      );
    case CONST.SEND_MAIL_DRIVER_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: true,
          isFailureMail: false,
          errorMessageMail: '',
        },
      );
    case CONST.SEND_MAIL_DRIVER_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: false,
          isFailureMail: true,
          errorMessageMail: action.payload,
        },
      );
    // -----------------
    // メール送信結果フラグクリア
    // -----------------
    case CONST.CLEAR_SEND_MAIL_DRIVER_RESULT_FLG:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: false,
          isFailureMail: false,
          errorMessageMail: '',
        },
      );
    // -----------------
    // queryパラメータの設定
    // -----------------
    case CONST.SET_DRIVER_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: Object.assign(
            {},
            state,
            action.payload,
          ),
        },
      );
    // -----------------
    // queryパラメータのクリア
    // -----------------
    case CONST.CLEAR_DRIVER_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: {
            currentPage: 0,
            itemsPerPage: 10,
          },
        },
      );
    default:
      return state;
  }
}

export default driver;
