import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const Auth = props => (props.isLoggedIn ? props.children : <Redirect to="/login" />);

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(Auth);
