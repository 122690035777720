import * as CONST from '../ActionTypes';

const initialState = {
  authLoading: true,
  isLoggedIn: false,
  errorMessage: '',
  // ログインユーザーの情報
  id: null,
  email: '',
  name: '',
  accessToken: '',
  refreshToken: '',
  paymentAgency: '',
  companyId: null,
  company: null,
  // option権限
  isAbledCsv: false,
  isAbledQr: false,
  isAbledTransactionAnalytics: false,
  isAbledBroadcastMail: false,
  isAbledSalesPersonList: false,
  isAbledSalesPersonCreate: false,
  isAbledProductCreate: false,
  isAbledCoupon: false,
  isAbledYamatoCsv: false,
  isAbledReceipt: false,
  isAbledInvoice: false,
  isAbledAccountTransfer: false,
  isAbledApi: false,
  isAbledLink: false,
  // 口座情報
  accountName: '',
  accountNumber: '',
  accountType: null,
  bankBranch: '',
  bankName: '',
  // パスワード更新用
  isSuccess: false,
  isFailure: false,
  updateErrorMessage: '',
  companyCustomerField: null,
  companyDriverField: null,
};

function auth(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // ログイン
    // -----------------
    case CONST.LOGIN_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isLoggedIn: true,
          errorMessage: '',
          id: action.payload.admin.id,
          email: action.payload.admin.email,
          name: action.payload.admin.name,
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          company: action.payload.company,
        },
      );
    case CONST.LOGIN_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isLoggedIn: false,
          errorMessage: 'Eメールかパスワードが正しくありません',
          id: null,
          email: '',
          name: '',
          accessToken: '',
          refreshToken: '',
          company: null,

        },
      );
    // -----------------
    // リフレッシュトークンでログイン
    // -----------------
    case CONST.REFRESH_TOKEN_LOGIN_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isLoggedIn: true,
          errorMessage: '',
          id: action.payload.admin.id,
          email: action.payload.admin.email,
          name: action.payload.admin.name,
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          authLoading: false,
          company: action.payload.company,
        },
      );
    case CONST.REFRESH_TOKEN_LOGIN_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isLoggedIn: false,
          errorMessage: '',
          id: null,
          email: '',
          name: '',
          accessToken: '',
          refreshToken: '',
          authLoading: false,
          company: null,
        },
      );
    // -----------------
    // パスワード更新
    // -----------------
    case CONST.UPDATE_PASSWORD_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isSuccess: true,
          isFailure: false,
          errorMessage: '',
        },
      );
    case CONST.UPDATE_PASSWORD_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isSuccess: false,
          isFailure: true,
          updateErrorMessage: action.payload,
        },
      );
    // -----------------
    // 結果フラグクリア
    // -----------------
    case CONST.CLEAR_UPDATE_PASSWORD_RESULT_FLG:
      return Object.assign(
        {},
        state,
        {
          isSuccess: false,
          isFailure: false,
          updateErrorMessage: '',
        },
      );
    // -----------------
    // ログアウト
    // -----------------
    case CONST.LOGOUT:
      return Object.assign(
        {},
        state,
        {
          authLoading: false,
          isLoggedIn: false,
          errorMessage: '',
          id: null,
          email: '',
          name: '',
          paymentAgency: '',
          companyId: null,
          isAbledCsv: false,
          isAbledQr: false,
          isAbledTransactionAnalytics: false,
          isAbledBroadcastMail: false,
          isAbledSalesPersonList: false,
          isAbledSalesPersonCreate: false,
          isAbledProductCreate: false,
          isAbledCoupon: false,
          isAbledYamatoCsv: false,
          isAbledReceipt: false,
          isAbledInvoice: false,
          isAbledAccountTransfer: false,
          isAbledApi: false,
          isAbledLink: false,
          accountName: '',
          accountNumber: '',
          accountType: null,
          bankBranch: '',
          bankName: '',
          accessToken: '',
          refreshToken: '',
          companyCustomerField: null,
          companyDriverField: null,
        },
      );
    default:
      return state;
  }
}

export default auth;
