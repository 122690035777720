import * as CONST from '../ActionTypes';

const initialState = {
  shippingCompanyList: [],
  shippingCompanyManagerList: [],
  employeeByCompanyList: [],
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function shippingCompany(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_SHIPPING_COMPANY_SUCCESS:
      return Object.assign({},
        state, {
          shippingCompanyList: action.payload,
        });
    case CONST.GET_LIST_SHIPPING_COMPANY_FAILURE:
      return Object.assign({},
        state, {
          shippingCompanyList: [],
        });
      // -----------------
      // queryパラメータの設定
      // -----------------
    case CONST.GET_LIST_SHIPPING_COMPANY_MANAGER_SUCCESS:
      return Object.assign({},
        state, {
          shippingCompanyManagerList: action.payload,
        });
    case CONST.GET_LIST_SHIPPING_COMPANY_MANAGER_FAILURE:
      return Object.assign({},
        state, {
          shippingCompanyManagerList: [],
        });
    case CONST.GET_LIST_EMPLOYEE_BY_COMPANY_SUCCESS:
      return Object.assign({},
        state, {
          employeeByCompanyList: action.payload,
        });
    case CONST.GET_LIST_EMPLOYEE_BY_COMPANY_FAILURE:
      return Object.assign({},
        state, {
          employeeByCompanyList: [],
        });

      // -----------------
      // queryパラメータの設定
      // -----------------
    case CONST.CREATE_SHIPPING_COMPANY_SUCCESS:
      return Object.assign({},
        state, {
          isSuccess: true,
          isFailure: false,
          errorMessage: '',
        });
    case CONST.CREATE_SHIPPING_COMPANY_FAILURE:
      return Object.assign({},
        state, {
          isSuccess: false,
          isFailure: true,
          errorMessage: action.payload,
        });
    case CONST.APPROVE_SHIPPING_COMPANY_MANAGER_SUCCESS:
      return Object.assign({},
        state, {
          isSuccess: true,
          isFailure: false,
          errorMessage: '',
        });
    case CONST.APPROVE_SHIPPING_COMPANY_MANAGER_FAILURE:
      return Object.assign({},
        state, {
          isSuccess: false,
          isFailure: true,
          errorMessage: action.payload,
        });
    case CONST.UPDATE_SHIPPING_COMPANY_SUCCESS:
      return Object.assign({},
        state, {
          isSuccess: true,
          isFailure: false,
          errorMessage: '',
        });
    case CONST.UPDATE_SHIPPING_COMPANY_FAILURE:
      return Object.assign({},
        state, {
          isSuccess: false,
          isFailure: true,
          errorMessage: action.payload,
        });
    case CONST.CLEAR_SHIPPING_COMPANY:
      return Object.assign({},
        state, {
          isSuccess: false,
          isFailure: false,
          errorMessage: '',
        });
    default:
      return state;
  }
}

export default shippingCompany;
