import * as CONST from '../ActionTypes';

const initialState = {
  supportingList: [],
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function supporting(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_SUPPORTING_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          supportingList: action.payload,
        },
      );
    case CONST.GET_LIST_SUPPORTING_FAILURE:
      return Object.assign(
        {},
        state,
        {
          supportingList: [],
        },
      );
    case CONST.SET_SUPPORTING_QUERY:
      return Object.assign({}, state, {
        query: action.payload,
      });
    default:
      return state;
  }
}

export default supporting;
