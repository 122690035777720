// sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// loading
export const LOADING = 'LOADING';
export const UNLOADING = 'UNLOADING';

// error
export const CLEAR_ERROR_FLG = 'CLEAR_ERROR_FLG';
export const ACCESS_DENIED = 'ACCESS_DENIED';

// result
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export const CLEAR_RESULT_FLG = 'CLEAR_RESULT_FLG';

// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REFRESH_TOKEN_LOGIN_SUCCESS = 'REFRESH_TOKEN_LOGIN_SUCCESS';
export const REFRESH_TOKEN_LOGIN_FAILURE = 'REFRESH_TOKEN_LOGIN_FAILURE';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const CLEAR_UPDATE_PASSWORD_RESULT_FLG = 'CLEAR_UPDATE_PASSWORD_RESULT_FLG';
export const LOGOUT = 'LOGOUT';

// customer
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';
export const GET_ALL_CUSTOMER_LIST_SUCCESS = 'GET_ALL_CUSTOMER_LIST_SUCCESS';
export const GET_ALL_CUSTOMER_LIST_FAILURE = 'GET_ALL_CUSTOMER_LIST_FAILURE';
export const GET_CSV_CUSTOMER_LIST_SUCCESS = 'GET_CSV_CUSTOMER_LIST_SUCCESS';
export const GET_CSV_CUSTOMER_LIST_FAILURE = 'GET_CSV_CUSTOMER_LIST_FAILURE';

export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_FAILURE = 'GET_CUSTOMER_DETAIL_FAILURE';
export const SET_CUSTOMER_SEARCH_PARAMS = 'SET_CUSTOMER_SEARCH_PARAMS';
export const CLEAR_CUSTOMER_SEARCH_PARAMS = 'CLEAR_CUSTOMER_SEARCH_PARAMS';

export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const CLEAR_DELETE_CUSTOMER_RESULT_FLG = 'CLEAR_DELETE_CUSTOMER_RESULT_FLG';

export const SEND_MAIL_CUSTOMER_SUCCESS = 'SEND_MAIL_CUSTOMER_SUCCESS';
export const SEND_MAIL_CUSTOMER_FAILURE = 'SEND_MAIL_CUSTOMER_FAILURE';
export const CLEAR_SEND_MAIL_CUSTOMER_RESULT_FLG = 'CLEAR_SEND_MAIL_CUSTOMER_RESULT_FLG';

export const SET_CUSTOMER_QUERY = 'SET_CUSTOMER_QUERY';
export const CLEAR_CUSTOMER_QUERY = 'CLEAR_CUSTOMER_QUERY';

// driver
export const GET_DRIVER_LIST_SUCCESS = 'GET_DRIVER_LIST_SUCCESS';
export const GET_DRIVER_LIST_FAILURE = 'GET_DRIVER_LIST_FAILURE';
export const GET_ALL_DRIVER_LIST_SUCCESS = 'GET_ALL_DRIVER_LIST_SUCCESS';
export const GET_ALL_DRIVER_LIST_FAILURE = 'GET_ALL_DRIVER_LIST_FAILURE';

export const GET_DRIVER_DETAIL_SUCCESS = 'GET_DRIVER_DETAIL_SUCCESS';
export const GET_DRIVER_DETAIL_FAILURE = 'GET_DRIVER_DETAIL_FAILURE';
export const SET_DRIVER_SEARCH_PARAMS = 'SET_DRIVER_SEARCH_PARAMS';
export const CLEAR_DRIVER_SEARCH_PARAMS = 'CLEAR_DRIVER_SEARCH_PARAMS';

export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAILURE = 'DELETE_DRIVER_FAILURE';
export const CLEAR_DELETE_DRIVER_RESULT_FLG = 'CLEAR_DELETE_DRIVER_RESULT_FLG';

export const SEND_MAIL_DRIVER_SUCCESS = 'SEND_MAIL_DRIVER_SUCCESS';
export const SEND_MAIL_DRIVER_FAILURE = 'SEND_MAIL_DRIVER_FAILURE';
export const CLEAR_SEND_MAIL_DRIVER_RESULT_FLG = 'CLEAR_SEND_MAIL_DRIVER_RESULT_FLG';

export const SET_DRIVER_QUERY = 'SET_DRIVER_QUERY';
export const CLEAR_DRIVER_QUERY = 'CLEAR_DRIVER_QUERY';

// delivery_requests
export const GET_DELIVERY_REQ_LIST_SUCCESS = 'GET_DELIVERY_REQ_LIST_SUCCESS';
export const GET_DELIVERY_REQ_LIST_FAILURE = 'GET_DELIVERY_REQ_LIST_FAILURE';
export const GET_ALL_DELIVERY_REQ_LIST_SUCCESS = 'GET_ALL_DELIVERY_REQ_LIST_SUCCESS';
export const GET_ALL_DELIVERY_REQ_LIST_FAILURE = 'GET_ALL_DELIVERY_REQ_LIST_FAILURE';
export const GET_CSV_DELIVERY_REQ_LIST_SUCCESS = 'GET_CSV_DELIVERY_REQ_LIST_SUCCESS';
export const GET_CSV_DELIVERY_REQ_LIST_FAILURE = 'GET_CSV_DELIVERY_REQ_LIST_FAILURE';

export const GET_DELIVERY_REQ_DETAIL_SUCCESS = 'GET_DELIVERY_REQ_DETAIL_SUCCESS';
export const GET_DELIVERY_REQ_DETAIL_FAILURE = 'GET_DELIVERY_REQ_DETAIL_FAILURE';
export const SET_DELIVERY_REQ_SEARCH_PARAMS = 'SET_DELIVERY_REQ_SEARCH_PARAMS';
export const CLEAR_DELIVERY_REQ_SEARCH_PARAMS = 'CLEAR_DELIVERY_REQ_SEARCH_PARAMS';

export const DELETE_DELIVERY_REQ_SUCCESS = 'DELETE_DELIVERY_REQ_SUCCESS';
export const DELETE_DELIVERY_REQ_FAILURE = 'DELETE_DELIVERY_REQ_FAILURE';
export const CLEAR_DELETE_DELIVERY_REQ_RESULT_FLG = 'CLEAR_DELETE_DELIVERY_REQ_RESULT_FLG';

export const SEND_MAIL_DELIVERY_REQ_SUCCESS = 'SEND_MAIL_DELIVERY_REQ_SUCCESS';
export const SEND_MAIL_DELIVERY_REQ_FAILURE = 'SEND_MAIL_DELIVERY_REQ_FAILURE';
export const CLEAR_SEND_MAIL_DELIVERY_REQ_RESULT_FLG = 'CLEAR_SEND_MAIL_DELIVERY_REQ_RESULT_FLG';

export const SET_DELIVERY_REQ_QUERY = 'SET_DELIVERY_REQ_QUERY';
export const CLEAR_DELIVERY_REQ_QUERY = 'CLEAR_DELIVERY_REQ_QUERY';

// prefecture
export const GET_PREF_LIST_SUCCESS = 'GET_PREF_LIST_SUCCESS';
export const GET_PREF_LIST_FAILURE = 'GET_PREF_LIST_FAILURE';

// pagination
export const SET_PAGINATE_COUNT = 'SET_PAGINATE_COUNT';
export const CLEAR_PAGINATE_COUNT = 'CLEAR_PAGINATE_COUNT';

// delivery_request_questionnaires
export const GET_QUESTION_LIST_SUCCESS = 'GET_QUESTION_LIST_SUCCESS';
export const GET_QUESTION_LIST_FAILURE = 'GET_QUESTION_LIST_FAILURE';

// delivery_request_suggestions
export const GET_SUGGESTIONS_LIST_SUCCESS = 'GET_SUGGESTIONS_LIST_SUCCESS';
export const GET_SUGGESTIONS_LIST_FAILURE = 'GET_SUGGESTIONS_LIST_FAILURE';

// delivery_request no suggestions a week
export const GET_NO_SUGGESTIONS_WEEK_LIST_SUCCESS = 'GET_NO_SUGGESTIONS_WEEK_LIST_SUCCESS';
export const GET_NO_SUGGESTIONS_WEEK_LIST_FAILURE = 'GET_NO_SUGGESTIONS_WEEK_LIST_FAILURE';
// get list questionnaires
export const GET_LIST_QUESTIONNAIRES_SUCCESS = 'GET_LIST_QUESTIONNAIRES_SUCCESS';
export const GET_LIST_QUESTIONNAIRES_FAILURE = 'GET_LIST_QUESTIONNAIRES_FAILURE';
export const SET_QUESTIONNAIRES_QUERY = 'SET_QUESTIONNAIRES_QUERY';
//shipping Company
export const GET_LIST_SHIPPING_COMPANY_SUCCESS = 'GET_LIST_SHIPPING_COMPANY_SUCCESS';
export const GET_LIST_SHIPPING_COMPANY_FAILURE = 'GET_LIST_SHIPPING_COMPANY_FAILURE';
export const CREATE_SHIPPING_COMPANY_SUCCESS = 'CREATE_SHIPPING_COMPANY_SUCCESS';
export const CREATE_SHIPPING_COMPANY_FAILURE = 'CREATE_SHIPPING_COMPANY_FAILURE';
export const UPDATE_SHIPPING_COMPANY_SUCCESS = 'UPDATE_SHIPPING_COMPANY_SUCCESS';
export const UPDATE_SHIPPING_COMPANY_FAILURE = 'UPDATE_SHIPPING_COMPANY_FAILURE';
export const CLEAR_SHIPPING_COMPANY = 'CLEAR_SHIPPING_COMPANY';
// shipping Company manager
export const GET_LIST_SHIPPING_COMPANY_MANAGER_SUCCESS = 'GET_LIST_SHIPPING_COMPANY_MANAGER_SUCCESS';
export const GET_LIST_SHIPPING_COMPANY_MANAGER_FAILURE = 'GET_LIST_SHIPPING_COMPANY_MANAGER_FAILURE';
export const APPROVE_SHIPPING_COMPANY_MANAGER_SUCCESS = 'APPROVE_SHIPPING_COMPANY_MANAGER_SUCCESS';
export const APPROVE_SHIPPING_COMPANY_MANAGER_FAILURE = 'APPROVE_SHIPPING_COMPANY_MANAGER_FAILURE';
export const GET_LIST_EMPLOYEE_BY_COMPANY_SUCCESS = 'GET_LIST_EMPLOYEE_BY_COMPANY_SUCCESS';
export const GET_LIST_EMPLOYEE_BY_COMPANY_FAILURE = 'GET_LIST_EMPLOYEE_BY_COMPANY_FAILURE';
// add notification
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';
export const GET_LIST_NOTIFICATION_SUCCESS = 'GET_LIST_NOTIFICATION_SUCCESS';
export const GET_LIST_NOTIFICATION_FAILURE = 'GET_LIST_NOTIFICATION_FAILURE';
export const SET_NOTIFICATION_QUERY = 'SET_NOTIFICATION_QUERY';
// add contents
export const GET_LIST_CONTENT_SUCCESS = 'GET_LIST_CONTENT_SUCCESS';
export const GET_LIST_CONTENT_FAILURE = 'GET_LIST_CONTENT_FAILURE';
export const SET_CONTENT_QUERY = 'SET_CONTENT_QUERY';
// member supporting
export const CREATE_SUPPORTING_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_SUPPORTING_FAILURE = 'CREATE_NOTIFICATION_FAILURE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const GET_LIST_SUPPORTING_SUCCESS = 'GET_LIST_SUPPORTING_SUCCESS';
export const GET_LIST_SUPPORTING_FAILURE = 'GET_LIST_SUPPORTING_FAILURE';
export const SET_SUPPORTING_QUERY = 'SET_SUPPORTING_QUERY';
