import * as CONST from '../ActionTypes';

export const loading = () => ({
  type: CONST.LOADING,
});

export const unLoading = () => ({
  type: CONST.UNLOADING,
});

export const actionType = (typeName, payload) => ({
  type: typeName,
  payload,
});

export const requestSuccess = () => ({
  type: CONST.REQUEST_SUCCESS,
});

export const requestFailure = errorMessage => ({
  type: CONST.REQUEST_FAILURE,
  payload: errorMessage,
});

export const accessDenied = () => ({
  type: CONST.ACCESS_DENIED,
});

export function setToken(refreshToken) {
  localStorage.setItem('refresh_token', refreshToken);
}
