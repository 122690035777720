import * as CONST from '../ActionTypes';

const initialState = {
  customerList: [],
  customerWeekList: [],
  allCustomerList: [],
  customer: {},
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
  isSuccessMail: false,
  isFailureMail: false,
  errorMessageMail: '',
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function customer(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 顧客一覧取得
    // -----------------
    case CONST.GET_CUSTOMER_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          customerList: action.payload,
        },
      );
    case CONST.GET_NO_SUGGESTIONS_WEEK_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          customerWeekList: action.payload,
        },
      );
    case CONST.GET_CUSTOMER_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          customerList: [],
        },
      );
    // -----------------
    // 顧客全件取得
    // -----------------
    case CONST.GET_ALL_CUSTOMER_LIST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          allCustomerList: action.payload,
        },
      );
    case CONST.GET_ALL_CUSTOMER_LIST_FAILURE:
      return Object.assign(
        {},
        state,
        {
          allCustomerList: [],
        },
      );
    // -----------------
    // 顧客詳細取得
    // -----------------
    case CONST.GET_CUSTOMER_DETAIL_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          customer: action.payload,
        },
      );
    case CONST.GET_CUSTOMER_DETAIL_FAILURE:
      return Object.assign(
        {},
        state,
        {
          customer: {},
        },
      );
    // -----------------
    // 検索パラメーターセット
    // -----------------
    case CONST.SET_CUSTOMER_SEARCH_PARAMS:
      return Object.assign(
        {},
        state,
        action.payload,
      );
    // -----------------
    // 検索パラメーターリセット
    // -----------------
    case CONST.CLEAR_CUSTOMER_SEARCH_PARAMS:
      return Object.assign(
        {},
        state,
        {
          sortParams: {
            createdAtAsc: true,
            idAsc: true,
            prefAsc: true,
            nameKanaAsc: true,
            birthdayAsc: true,
          },
        },
      );
    case CONST.SEND_MAIL_CUSTOMER_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: true,
          isFailureMail: false,
          errorMessageMail: '',
        },
      );
    case CONST.SEND_MAIL_CUSTOMER_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: false,
          isFailureMail: true,
          errorMessageMail: action.payload,
        },
      );
    // -----------------
    // メール送信結果フラグクリア
    // -----------------
    case CONST.CLEAR_SEND_MAIL_CUSTOMER_RESULT_FLG:
      return Object.assign(
        {},
        state,
        {
          isSuccessMail: false,
          isFailureMail: false,
          errorMessageMail: '',
        },
      );
    // -----------------
    // queryパラメータの設定
    // -----------------
    case CONST.SET_CUSTOMER_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: Object.assign(
            {},
            state,
            action.payload,
          ),
        },
      );
    // -----------------
    // queryパラメータのクリア
    // -----------------
    case CONST.CLEAR_CUSTOMER_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: {
            currentPage: 0,
            itemsPerPage: 10,
          },
        },
      );
    default:
      return state;
  }
}

export default customer;
