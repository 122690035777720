import * as CONST from '../ActionTypes';

const initialState = {
  count: 0,
};

function pagination(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // 都道府県一覧取得
    // -----------------
    case CONST.SET_PAGINATE_COUNT:
      return Object.assign(
        {},
        state,
        {
          count: action.payload,
        },
      );
    case CONST.CLEAR_PAGINATE_COUNT:
      return Object.assign(
        {},
        state,
        {
          count: 0,
        },
      );
    default:
      return state;
  }
}

export default pagination;
