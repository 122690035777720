import * as CONST from '../ActionTypes';

export type SidebarState = {
  sidebarShow: any,
}

const initialState: SidebarState = {
  sidebarShow: 'responsive',
};

function sidebar(state = initialState, action) {
  switch (action.type) {
    case CONST.TOGGLE_SIDEBAR:
      return Object.assign(
        {},
        state,
        { sidebarShow: action.payload },
      );
    default:
      return state;
  }
}

export default sidebar;
