import * as CONST from '../ActionTypes';
import * as util from './index';

export const loading = () => ({
  type: CONST.LOADING,
});

export const unLoading = () => ({
  type: CONST.UNLOADING,
});

export const requestSuccess = (typeName, result) => ({
  type: typeName,
  payload: result,
});

export const requestFailure = (typeName, result) => ({
  type: typeName,
  payload: result,
});

/**
 * ログイン
 */
export const login = param => (dispatch) => {
  dispatch(loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/admins/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then(response => response.json())
    .then((payload) => {
      if (payload.access_token && payload.refresh_token) {
        util.setToken(payload.refresh_token);
        dispatch(requestSuccess(CONST.LOGIN_SUCCESS, payload));
        dispatch(unLoading());
      } else {
        dispatch(requestFailure(CONST.LOGIN_FAILURE, null));
        dispatch(unLoading());
      }
    })
    .catch((error) => {
      dispatch(requestFailure(CONST.LOGIN_FAILURE, error));
      dispatch(unLoading());
    });
};

/**
 * リフレッシュトークンでログイン
 */
export const refreshTokenLogin = param => (dispatch) => {
  dispatch(loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/admins/refresh_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then(response => response.json())
    .then((payload) => {
      if (payload.access_token && payload.refresh_token) {
        dispatch(requestSuccess(CONST.REFRESH_TOKEN_LOGIN_SUCCESS, payload));
        dispatch(unLoading());
        // デバイスにリフレッシュトークンを保存
        localStorage.setItem('refresh_token', payload.refresh_token);
      } else {
        dispatch(requestFailure(CONST.REFRESH_TOKEN_LOGIN_FAILURE, null));
        dispatch(unLoading());
      }
    })
    .catch((error) => {
      dispatch(requestFailure(CONST.REFRESH_TOKEN_LOGIN_FAILURE, error));
      dispatch(unLoading());
    });
};

/**
 * パスワード更新
 */
export const updatePassword = (accessToken, param) => (dispatch) => {
  dispatch(loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/admins/update_password`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((payload) => {
      if (payload.status === 200) {
        dispatch(requestSuccess(CONST.UPDATE_PASSWORD_SUCCESS, payload));
      } else {
        dispatch(requestFailure(CONST.UPDATE_PASSWORD_FAILURE, '時間をおいてもう一度お試しください。'));
      }
      dispatch(unLoading());
    })
    .catch(() => {
      dispatch(requestFailure(CONST.UPDATE_PASSWORD_FAILURE, '時間をおいてもう一度お試しください。'));
      dispatch(unLoading());
    });
};

/**
 * 結果フラグクリア
 */
export const clearResultFlg = () => ({
  type: CONST.CLEAR_UPDATE_PASSWORD_RESULT_FLG,
});

/**
 * ログアウト
 */
export const logout = () => ({
  type: CONST.LOGOUT,
});
