import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { refreshTokenLogin } from './redux/actions/auth';
import './scss/style.scss';
import Auth from './Auth';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Loading...</div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const authLoading = useSelector(state => state.auth.authLoading, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token');
    const param = {
      refresh_token: refreshToken,
    };
    dispatch(refreshTokenLogin(param));
  }, [dispatch]);

  return (
    authLoading
      ? loading
      : (
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/403" name="Page 403" render={props => <Page403 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Auth>
                <Switch>
                  <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                </Switch>
              </Auth>
            </Switch>
          </React.Suspense>
        </HashRouter>
      )
  );
};

export default App;
