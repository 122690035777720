import { combineReducers } from 'redux';
import auth from './auth';
import sidebar from './sidebar';
import loading from './loading';
import error from './error';
import customer from './customer';
import pagination from './pagination';
import driver from './driver';
import deliveryReq from './deliveryRequest';
import question from './question';
import questionnaires from './questionnaires';
import suggestion from './suggestion';
import notification from './notification';
import content from './content';
import shippingCompany from './shippingCompany';
import supporting from './supporting';


export default combineReducers({
  auth,
  sidebar,
  loading,
  error,
  customer,
  pagination,
  driver,
  deliveryReq,
  question,
  suggestion,
  questionnaires,
  notification,
  content,
  shippingCompany,
  supporting,
});
