import * as CONST from '../ActionTypes';

const initialState = {
  questionnairesList: [],
  query: {
    currentPage: 0,
    itemsPerPage: 10,
  },
};

function questionnaires(state = initialState, action) {
  switch (action.type) {
    case CONST.GET_LIST_QUESTIONNAIRES_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          questionnairesList: action.payload,
        },
      );
    case CONST.GET_LIST_QUESTIONNAIRES_FAILURE:
      return Object.assign(
        {},
        state,
        {
          questionnairesList: [],
        },
      );
      // -----------------
    // queryパラメータの設定
    // -----------------
    case CONST.SET_QUESTIONNAIRES_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: Object.assign(
            {},
            state,
            action.payload,
          ),
        },
      );
    // -----------------
    // queryパラメータのクリア
    // -----------------
    case CONST.CLEAR_DELIVERY_REQ_QUERY:
      return Object.assign(
        {},
        state,
        {
          query: {
            currentPage: 0,
            itemsPerPage: 10,
          },
        },
      );
    default:
      return state;
  }
}

export default questionnaires;
